import FlowNotification from '@Components/flow/FlowNotification'
import FlowTransactionOverlay from '@Components/flow/FlowTransactionOverlay'
import Spacer from '@Components/generics/Spacer'
import Footer from '@Components/navigation/Footer'
import NavBar from '@Components/navigation/NavBar'
import RootAlerts from '@Components/root/RootAlerts'
import { CacheProvider } from '@emotion/react'
import '@Firebase/config'
import createEmotionCache from '@Mui/createEmotionCache'
import { LocalizationProvider } from '@mui/lab'
import DateAdapter from '@mui/lab/AdapterDateFns'
import { Box, Slide, useScrollTrigger } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import theme from '@Mui/theme'
import AppStateProvider from '@State/AppStateProvider'
import store from '@State/store'
import Head from 'next/head'
import NextNProgress from 'nextjs-progressbar'
import PropTypes from 'prop-types'
import React from 'react'
import { Provider } from 'react-redux'
import 'src/global/fclConfig'
import '../App.css'
import '../cards.css'

const FadeOnScroll = (props) => {
  const { children } = props
  const trigger = useScrollTrigger({ threshold: 0 })
  return <Slide in={!trigger}>{children}</Slide>
}

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache()

const App = (props) => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props

  return (
    <Provider store={store}>
      <NextNProgress />
      <CacheProvider value={emotionCache}>
        <Head>
          <meta name="viewport" content="initial-scale=1, width=device-width" />
          <link rel="shortcut icon" href="/favicon.ico" />
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/apple-touch-icon.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/favicon-16x16.png"
          />
          <link rel="manifest" href="/site.webmanifest" />
        </Head>
        <LocalizationProvider dateAdapter={DateAdapter}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <AppStateProvider>
              <Box
                display="flex"
                flexDirection="column"
                sx={{ height: '100vh' }}
              >
                <FadeOnScroll>
                  <NavBar />
                </FadeOnScroll>
                <Spacer />

                <FlowNotification />
                <FlowTransactionOverlay />

                <Box sx={{ flexGrow: '1', marginBottom: 3 }}>
                  <Component {...pageProps} />
                </Box>

                <RootAlerts />

                <Footer />
              </Box>
            </AppStateProvider>
          </ThemeProvider>
        </LocalizationProvider>
      </CacheProvider>
    </Provider>
  )
}

App.propTypes = {
  Component: PropTypes.elementType.isRequired,
  emotionCache: PropTypes.object,
  pageProps: PropTypes.object.isRequired,
}

export default App
