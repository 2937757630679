import firebase from 'firebase/compat/app'

export const useFirebaseLogout = () => {
  const logout = async () => {
    await firebase.auth().signOut()
  }

  return logout
}

export default useFirebaseLogout
