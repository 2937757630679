import { configureStore } from '@reduxjs/toolkit'
import firebaseUserReducer from '@State/firebase/firebaseUserReducer'
import flowUserReducer from '@State/flow/flowUserReducer'
import transactionReducer from '@State/flow/transactionReducer'
import rootReducer from './rootReducer'

const makeStore = () => {
  return configureStore({
    reducer: {
      root: rootReducer,
      firebaseUser: firebaseUserReducer,
      flowUser: flowUserReducer,
      transaction: transactionReducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
  })
}

const store = makeStore()

export default store
