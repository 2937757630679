// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
  release:
    process.env.NODE_ENV === 'production'
      ? 'creatorbay@' + process.env.npm_package_version
      : process.env.NODE_ENV,
  dsn:
    process.env.NODE_ENV === 'development'
      ? null
      : SENTRY_DSN ||
        'https://7d74d6a85ce2441898e639a3a4415606@o1176949.ingest.sentry.io/6275550',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
})
