import useAccountInitializer from '@Hooks/flow/useAccountInitializer'
import CloseIcon from '@mui/icons-material/Close'
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material'
import { setShowFlowUserInitialize } from '@State/flow/flowUserActions'
import {
  selectCheckingFlowUserInitialized,
  selectFlowUser,
  selectIsFlowUserInitialized,
  selectShowFlowUserInitialize,
} from '@State/flow/flowUserReducer'
import { useAppDispatch, useAppSelector } from '@State/hooks'
import React from 'react'

const FlowNotification = (props) => {
  const isFlowUserInitialized = useAppSelector(selectIsFlowUserInitialized)
  const checkingFlowUserInitialized = useAppSelector(
    selectCheckingFlowUserInitialized
  )
  const showFlowUserInitialize = useAppSelector(selectShowFlowUserInitialize)
  const flowUser = useAppSelector(selectFlowUser)
  const { initializeAccount, isInitializingAccount } = useAccountInitializer()

  const dispatch = useAppDispatch()

  const onClose = () => {
    dispatch(setShowFlowUserInitialize(false))
  }

  if (!isFlowUserInitialized && showFlowUserInitialize) {
    return (
      <Dialog fullWidth maxWidth="xs" open={true}>
        <DialogTitle sx={{ display: 'flex', justifyContent: 'center' }}>
          Initialize FLOW Wallet
          <IconButton
            sx={{ position: 'absolute', right: 1, top: 1 }}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            textAlign: 'center',
          }}
        >
          <Typography variant="body1" sx={{ mb: 3 }}>
            Your FLOW wallet needs to be initialized to be used on CreatorBay.
            This will add the ability to deposit and withdraw both FUSD and
            CreatorBay NFTs.
          </Typography>
          <Button
            variant="contained"
            onClick={initializeAccount}
            disabled={isInitializingAccount}
          >
            {isInitializingAccount ? 'Initializing...' : 'Initialize'}
          </Button>
        </DialogContent>
      </Dialog>
    )
  }

  if (
    flowUser?.addr &&
    !isFlowUserInitialized &&
    !checkingFlowUserInitialized
  ) {
    return (
      <Box sx={{ width: '100%', cursor: 'pointer', mb: 1 }}>
        <Alert
          severity="info"
          variant="filled"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="body2"
            component="span"
            sx={{ fontWeight: 'bold' }}
          >
            Please initialize your FLOW wallet
          </Typography>
          <Button
            size="small"
            variant="contained"
            color="secondary"
            onClick={initializeAccount}
            sx={{ mx: 2 }}
          >
            Initialize wallet
          </Button>
          <Button
            size="small"
            color="secondary"
            variant="contained"
            href="/initialize"
          >
            Learn more
          </Button>
        </Alert>
      </Box>
    )
  }

  return null
}

export default FlowNotification
