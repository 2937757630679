import * as fcl from '@onflow/fcl'

export const useFlowLogin = () => {
  const login = async () => {
    await fcl.logIn()
  }

  return login
}

export default useFlowLogin
