import { createReducer } from '@reduxjs/toolkit'
import {
  ERROR,
  IDLE,
  setTransactionState,
  START,
  SUCCESS,
} from '@State/flow/transactionActions'

const initialState = {
  isLoading: false,
  isError: false,
}

export const transactionReducer = createReducer(initialState, (builder) => {
  builder.addCase(setTransactionState, (state, { payload }) => {
    switch (payload) {
      case START:
        state.isLoading = true
        state.isError = false
        break

      case SUCCESS:
        state.isLoading = false
        state.isError = false
        break

      case ERROR:
        state.isLoading = false
        state.isError = true
        break

      case IDLE:
      default:
        state.isLoading = false
        state.isError = false
        break
    }
  })
})

export const selectTransactionState = (state) => {
  return {
    isLoading: state.transaction.isLoading,
    isError: state.transaction.isError,
  }
}

export default transactionReducer
