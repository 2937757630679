import { createTheme } from '@mui/material/styles'

// Create a theme instance.
const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#1199fa',
    },
    primaryLight: {
      main: '#11d0fa',
    },
    secondary: {
      main: '#ff7b1f',
    },
    secondaryLight: {
      main: '#ff964d',
    },
    white: {
      main: '#ffffff',
    },
    background: {
      default: '#0b1426',
      paper: '#0b1426',
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
    h1: {
      fontWeight: 'bold',
    },
    h2: {
      fontWeight: 'bold',
    },
    h3: {
      fontWeight: 'bold',
    },
    h4: {
      fontWeight: 'bold',
    },
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '12px',
        },
      },
    },
    MuiDropzoneArea: {
      styleOverrides: {
        root: {
          width: '100%',
          height: '100%',
          minHeight: '100%',
          maxHeight: '100%',
          padding: '8px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'rgba(0,0,0,0.25)',
          borderColor: 'rgba(255,255,255,0.5) !important',
        },
        text: {
          fontSize: '12px',
          margin: '8px 0 !important',
        },
        icon: {
          width: '30px',
          height: '30px',
        },
      },
    },
    MuiDropzonePreviewList: {
      styleOverrides: {
        root: {
          display: 'none',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: '32px',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minHeight: '32px',
          minWidth: '120px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        // label: {
        //   opacity: 0.999, // Fix for css animation font-weight flickering
        // },
        contained: {
          color: '#ffffff',
        },
      },
    },
  },
})

export default theme
