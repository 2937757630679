import { createAction } from '@reduxjs/toolkit'

export const IDLE = 'IDLE'
export const START = 'START'
export const SUCCESS = 'SUCCESS'
export const ERROR = 'ERROR'

export const DECLINE_RESPONSE = 'Declined: Externally Halted'

export const setTransactionState = createAction(
  'flow/transaction/setTransactionState'
)
