import {
  getFirebaseAccount,
  setFirebaseUser,
} from '@State/firebase/firebaseUserActions'
import {
  selectFirebaseAccount,
  selectFirebaseClaims,
  selectFirebaseUser,
  selectIsCheckingFirebaseAuthState,
  selectIsFirebaseLoggedIn,
} from '@State/firebase/firebaseUserReducer'
import { useAppDispatch, useAppSelector } from '@State/hooks'
import firebase from 'firebase/compat/app'
import { useEffect } from 'react'

export const useFirebaseState = () => {
  const isFirebaseLoggedIn = useAppSelector(selectIsFirebaseLoggedIn)
  const isCheckingFirebaseAuthState = useAppSelector(
    selectIsCheckingFirebaseAuthState
  )

  const firebaseUser = useAppSelector(selectFirebaseUser)
  const firebaseAccount = useAppSelector(selectFirebaseAccount)
  const firebaseClaims = useAppSelector(selectFirebaseClaims)

  const dispatch = useAppDispatch()

  const doGetAccount = () => {
    if (isFirebaseLoggedIn && !firebaseAccount) {
      console.log('useFirebaseState doGetAccount')
      dispatch(getFirebaseAccount())
    }
  }

  useEffect(() => {
    console.log('useFirebaseState: useEffect')
    firebase.auth().onAuthStateChanged((user) => {
      console.log('%c useFirebaseState checkAuthState', 'color:orange', user)
      dispatch(setFirebaseUser(user))
    })

    // eslint-disable-next-line
  }, [])

  // eslint-disable-next-line
  useEffect(() => doGetAccount(), [isFirebaseLoggedIn])

  return {
    isFirebaseLoggedIn,
    isCheckingFirebaseAuthState,
    firebaseUser,
    firebaseAccount,
    firebaseClaims,
  }
}

export default useFirebaseState
