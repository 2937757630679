import { createRequest } from '@Firebase/request'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { addRootMessage } from '@State/rootActions'

export const updateAccountProfile = createAsyncThunk(
  'firebase/profile/updateAccountProfile',
  async (values, { dispatch, rejectWithValue }) => {
    const callback = values.callback
    delete values.callback
    try {
      console.log(
        '%c account_profiles-updateAccountProfile:',
        'color:aqua',
        values
      )
      const req = createRequest('account_profiles-updateAccountProfile')
      const response = await req({ ...values })
      console.log(
        '%c account_profiles-updateAccountProfile:',
        'color:lightgreen',
        response
      )
      dispatch(addRootMessage({ type: 'success', message: 'Profile updated!' }))
      callback(response.data.id)
      return response.data
    } catch (error) {
      console.log(
        '%c account_profiles-updateAccountProfile:',
        'color:lightred',
        error
      )
      dispatch(addRootMessage({ type: 'error', message: error.message }))
      callback(null)
      return rejectWithValue(error.message)
    }
  }
)

export const updateAccountFlowAddress = createAsyncThunk(
  'firebase/profile/updateAccountFlowAddress',
  async (values, { dispatch, rejectWithValue }) => {
    const callback = values.callback
    delete values.callback
    try {
      console.log(
        '%c account_profiles-updateAccountFlowAddress:',
        'color:aqua',
        values
      )
      const req = createRequest('account_profiles-updateAccountFlowAddress')
      const response = await req({ ...values })
      console.log(
        '%c account_profiles-updateAccountFlowAddress:',
        'color:lightgreen',
        response
      )
      callback(response.data)
      return response.data
    } catch (error) {
      console.log(
        '%c account_profiles-updateAccountFlowAddress:',
        'color:lightred',
        error
      )
      dispatch(addRootMessage({ type: 'error', message: error.message }))
      callback(null)
      return rejectWithValue(error.message)
    }
  }
)

export const uploadAvatarImage = createAsyncThunk(
  'firebase/profile/uploadAvatarImage',
  async (values, { dispatch, rejectWithValue }) => {
    const callback = values.callback
    delete values.callback
    try {
      console.log(
        '%c account_profiles-uploadAvatarImage:',
        'color:aqua',
        values
      )
      const req = createRequest('account_profiles-uploadAvatarImage')
      const response = await req({ ...values })
      console.log(
        '%c account_profiles-uploadAvatarImage:',
        'color:lightgreen',
        response
      )
      callback(response.data)
      return response.data
    } catch (error) {
      console.log(
        '%c account_profiles-uploadAvatarImage:',
        'color:lightred',
        error
      )
      callback(null)
      dispatch(addRootMessage({ type: 'error', message: error.message }))
      return rejectWithValue(error.message)
    }
  }
)

export const uploadBannerImage = createAsyncThunk(
  'firebase/profile/uploadBannerImage',
  async (values, { dispatch, rejectWithValue }) => {
    const callback = values.callback
    delete values.callback
    try {
      console.log(
        '%c account_profiles-uploadBannerImage:',
        'color:aqua',
        values
      )
      const req = createRequest('account_profiles-uploadBannerImage')
      const response = await req({ ...values })
      console.log(
        '%c account_profiles-uploadBannerImage:',
        'color:lightgreen',
        response
      )
      callback(response.data)
      return response.data
    } catch (error) {
      console.log(
        '%c account_profiles-uploadBannerImage:',
        'color:lightred',
        error
      )
      callback(null)
      dispatch(addRootMessage({ type: 'error', message: error.message }))
      return rejectWithValue(error.message)
    }
  }
)
