import { Box } from '@mui/system'
import React from 'react'

const Spacer = (props) => {
  const { height = '64px', small = '48px' } = props

  return (
    <Box
      sx={{
        minHeight: { xs: small, sm: height },
        maxHeight: { xs: small, sm: height },
        height: { xs: small, sm: height },
        display: 'inline-block',
      }}
    />
  )
}

export default Spacer
