import LoadingFill from '@Components/generics/LoadingFill'
import { Box, Typography } from '@mui/material'
import { selectTransactionState } from '@State/flow/transactionReducer'
import { useAppSelector } from '@State/hooks'
import React from 'react'

const FlowTransactionOverlay = (props) => {
  const transactionState = useAppSelector(selectTransactionState)

  if (transactionState?.isLoading) {
    return (
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 10000,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }}
      >
        <LoadingFill
          extra={
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              sx={{
                mt: 2,
                py: 1,
                px: 2,
                backgroundColor: 'rgba(0, 0, 0, 0.8)',
                borderRadius: '8px',
              }}
            >
              <Typography
                variant="body1"
                sx={{ fontWeight: 'bold' }}
                gutterBottom
              >
                Waiting on blockchain network...
              </Typography>
              <Typography variant="body2">This may take a moment</Typography>
            </Box>
          }
        />
      </Box>
    )
  }

  return null
}

export default FlowTransactionOverlay
