import { Box } from '@mui/system'
import React from 'react'

const Logotype = (props) => {
  const { sx = {} } = props

  return (
    <Box component="span" sx={sx}>
      <Box component="span" sx={{ color: 'primary.main' }}>
        creator
      </Box>
      <Box component="span" sx={{ color: 'secondary.main' }}>
        bay
      </Box>
    </Box>
  )
}

export default Logotype
