import { firebaseConfig } from '@Firebase/config'
import firebase from 'firebase/compat/app'

export const createRequest = (endpoint) => {
  return firebase.functions().httpsCallable(endpoint)
}

export const doFetch = async (endpoint, data = null) => {
  const url = getUrl(endpoint)
  console.log('%c doFetch:', 'color:aqua', url, data)
  const response = await fetch(url, {
    method: data ? 'POST' : 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    ...(data ? { body: JSON.stringify({ ...data }) } : {}),
  })
  let result = null
  try {
    result = await response.json()
  } catch (e) {
    console.log('%c doFetch:', 'color:lightred', endpoint, data, e)
  }
  return result
}

const getUrl = (endpoint) => {
  return process.env.NODE_ENV === 'production'
    ? `https://us-central1-${firebaseConfig.projectId}.cloudfunctions.net/${endpoint}`
    : process.env.NEXT_PUBLIC_IS_TESTING === 'true'
    ? `https://us-central1-${firebaseConfig.projectId}.cloudfunctions.net/${endpoint}`
    : `http://localhost:5001/creatorbay/us-central1/${endpoint}`
}

const request = {
  createRequest,
  doFetch,
}

export default request
