import CloseIcon from '@mui/icons-material/Close'
import { Alert, IconButton, Snackbar } from '@mui/material'
import { useAppDispatch } from '@State/hooks'
import { dismissRootMessage } from '@State/rootActions'
import React from 'react'
import { useSelector } from 'react-redux'

let lastMessage = { type: 'error', message: '' }

const RootAlerts = () => {
  const messages = useSelector((state) => state.root.messages)

  const dispatch = useAppDispatch()

  lastMessage = messages[0] ? messages[0] : lastMessage
  const showMessage = messages.length > 0

  return (
    <Snackbar
      open={showMessage}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert
        severity={lastMessage.type}
        variant="filled"
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => dispatch(dismissRootMessage())}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
      >
        {lastMessage.message}
      </Alert>
    </Snackbar>
  )
}

export default RootAlerts
