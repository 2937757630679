import LinkContainer from '@Components/generics/LinkContainer'
import { Menu, MenuItem } from '@mui/material'
import React from 'react'

const GenericMenu = (props) => {
  const { anchor, onClose, items, onClick } = props

  console.log(items)

  return (
    <Menu
      sx={{ '& .MuiMenu-paper': { minWidth: '150px !important' } }}
      anchorEl={anchor}
      keepMounted
      open={Boolean(anchor)}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      {items &&
        items.map((item, index) =>
          item.href ? (
            <MenuItem key={index} dense>
              <LinkContainer href={item.href} sx={{ color: '#fff' }}>
                {item.icon &&
                  React.createElement(item.icon, {
                    style: { marginRight: '8px' },
                  })}{' '}
                {item.title}
              </LinkContainer>
            </MenuItem>
          ) : (
            <MenuItem key={index} dense onClick={(e) => onClick(e, item)}>
              {item.icon &&
                React.createElement(item.icon, {
                  style: { marginRight: '8px' },
                })}{' '}
              {item.title}
            </MenuItem>
          )
        )}
    </Menu>
  )
}

export default GenericMenu
