import publicConfig from '@Global/publicConfig'
import { createReducer } from '@reduxjs/toolkit'
import {
  checkIsAccountInitialized,
  checkIsAdminInitialized,
  setFlowUser,
  setShowFlowUserInitialize,
} from '@State/flow/flowUserActions'

const initialState = {
  loggingIn: false,
  flowUser: null,
  isFlowUserInitialized: false,
  checkingFlowUserInitialized: false,
  showFlowUserInitialize: false,

  isServiceAccountUserInitialized: false,
  isServiceAccountAdminInitialized: false,
}

export const flowUserReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setFlowUser, (state, { payload }) => {
      console.log('%c flowUserReducer setflowUser', 'color:lightgreen', payload)
      state.flowUser = payload
      if (!payload) {
        state.loggingIn = false
        state.flowUser = null
        state.isFlowUserInitialized = false
      }
    })

    .addCase(setShowFlowUserInitialize, (state, { payload }) => {
      console.log(
        '%c flowUserReducer setShowFlowUserInitialize',
        'color:lightgreen',
        payload
      )
      state.showFlowUserInitialize = payload
    })

    .addCase(checkIsAccountInitialized.pending, (state) => {
      console.log(
        '%c flowUserReducer checkIsAccountInitialized',
        'color:yellow'
      )
      state.checkingFlowUserInitialized = true
    })
    .addCase(checkIsAccountInitialized.fulfilled, (state, { payload }) => {
      console.log(
        '%c flowUserReducer checkIsAccountInitialized',
        'color:lightgreen',
        payload
      )
      state.isFlowUserInitialized = payload.result
      state.checkingFlowUserInitialized = false
      if (payload.addr === publicConfig.flowAddress) {
        state.isServiceAccountUserInitialized = payload.result
      }
    })

    .addCase(checkIsAdminInitialized.fulfilled, (state, { payload }) => {
      console.log(
        '%c flowUserReducer checkIsAdminInitialized',
        'color:lightgreen',
        payload
      )
      state.isServiceAccountAdminInitialized = payload
    })
})

export const selectFlowUser = (state) => state.flowUser.flowUser
export const selectIsFlowUserInitialized = (state) =>
  state.flowUser.isFlowUserInitialized
export const selectCheckingFlowUserInitialized = (state) =>
  state.flowUser.checkingFlowUserInitialized
export const selectShowFlowUserInitialize = (state) =>
  state.flowUser.showFlowUserInitialize
export const selectIsServiceAccountUserInitialized = (state) =>
  state.flowUser.isServiceAccountUserInitialized
export const selectIsServiceAccountAdminInitialized = (state) =>
  state.flowUser.isServiceAccountAdminInitialized

export default flowUserReducer
