import IS_ACCOUNT_INITIALIZED from '@Cadence/scripts/is_account_initialized.cdc'
import { arg, args, decode, script, send } from '@onflow/fcl'
import { Address } from '@onflow/types'

export const isAccountInitialized = async (address) => {
  const response = await send([
    script(IS_ACCOUNT_INITIALIZED),
    args([arg(address, Address)]),
  ])
  return decode(response)
}
