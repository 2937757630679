import { createRequest } from '@Firebase/request'
import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { addRootMessage } from '@State/rootActions'

export const setFirebaseUser = createAction('firebase/user/setFirebaseUser')

export const getFirebaseAccount = createAsyncThunk(
  'firebase/user/getFirebaseAccount',
  async (_, { dispatch }) => {
    let account = null
    let claims = null
    try {
      console.log('%c accounts-getAccount:', 'color:aqua')
      const req = createRequest('accounts-getAccount')
      const response = await req()
      console.log('%c accounts-getAccount:', 'color:lightgreen', response)
      account = response.data.account
      claims = response.data.claims
      return { account, claims }
    } catch (error) {
      dispatch(addRootMessage({ type: 'error', message: error.message }))
      throw new Error(error.message)
    }
  }
)
