import * as fcl from '@onflow/fcl'
import {
  checkIsAccountInitialized,
  setFlowUser,
} from '@State/flow/flowUserActions'
import {
  selectFlowUser,
  selectIsServiceAccountAdminInitialized,
  selectIsServiceAccountUserInitialized,
} from '@State/flow/flowUserReducer'
import { useAppDispatch, useAppSelector } from '@State/hooks'
import { useEffect } from 'react'

export const useFlowState = () => {
  const isServiceAccountUserInitialized = useAppSelector(
    selectIsServiceAccountUserInitialized
  )
  const isServiceAccountAdminInitialized = useAppSelector(
    selectIsServiceAccountAdminInitialized
  )

  const flowUser = useAppSelector(selectFlowUser)

  const dispatch = useAppDispatch()

  useEffect(() => {
    console.log('useFlowState: useEffect')
    fcl.currentUser().subscribe((newUser) => {
      console.log('fcl.currentUser().subscribe', newUser)
      if (newUser?.loggedIn) {
        dispatch(setFlowUser(newUser))
        dispatch(checkIsAccountInitialized(newUser.addr))
      } else {
        dispatch(setFlowUser(null))
      }
    })

    // eslint-disable-next-line
  }, [])

  return {
    isServiceAccountUserInitialized,
    isServiceAccountAdminInitialized,
    flowUser,
  }
}

export default useFlowState
