import { CircularProgress } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'

const LoadingFill = (props) => {
  const { minHeight, height, extra = null } = props

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      sx={{
        width: '100%',
        height: height || '100%',
        minHeight: minHeight || '200px',
      }}
    >
      <CircularProgress color="primary" />
      {extra && <>{extra}</>}
    </Box>
  )
}

export default LoadingFill
