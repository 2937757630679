import publicConfig from '@Global/publicConfig'
import { config } from '@onflow/fcl'
import { send as grpcSend } from '@onflow/transport-grpc'

config()
  .put('app.detail.title', 'CreatorBay')
  .put(
    'app.detail.icon',
    `${new URL(publicConfig.appUrl).origin}/images/logo-300x300.jpg`
  )

  .put('env', publicConfig.chainEnv)
  .put('faucet', publicConfig.faucetAddress)
  .put('accessNode.api', publicConfig.flowAccessApiUrl)
  .put('discovery.wallet', publicConfig.walletDiscovery)
  .put('sdk.transport', grpcSend)

  .put('0xFungibleToken', publicConfig.contractFungibleToken)
  .put('0xNonFungibleToken', publicConfig.contractNonFungibleToken)
  .put('0xMetadataViews', publicConfig.contractMetadataViews)
  .put('0xNFTStorefront', publicConfig.contractNFTStorefront)
  .put('0xFlowToken', publicConfig.contractFlowToken)
  .put('0xFUSD', publicConfig.contractFUSD)

  .put('0xCreatorBay', publicConfig.contractCreatorBay)
  .put('0xVoucher', publicConfig.contractVoucher)
  .put('0xNFTItemSetStorefront', publicConfig.contractNFTItemSetStorefront)
