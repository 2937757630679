import { useTheme } from '@mui/material'
import Link from 'next/link'
import React from 'react'

const LinkContainer = (props) => {
  const { href, target = '_self', children, sx = {} } = props

  const theme = useTheme()

  return (
    <Link href={href} passHref>
      <a
        style={{
          textDecoration: 'none',
          color: theme.palette.primary.main,
          ...sx,
        }}
        target={target}
      >
        {children}
      </a>
    </Link>
  )
}

export default LinkContainer
