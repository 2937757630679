import 'firebase/compat/analytics'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/functions'
import 'firebase/compat/storage'

export const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_APIKEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTHDOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECTID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APPID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENTID,
}

if (typeof window !== 'undefined' && !window.initialized) {
  firebase.initializeApp(firebaseConfig)
  if (
    process.env.NODE_ENV === 'development' &&
    process.env.NEXT_PUBLIC_IS_TESTING === 'false'
  ) {
    firebase.functions().useEmulator('localhost', 5001)
  }
  window.initialized = true
  console.log('%c FIREBASE INITIALIZED!', 'color:lightgreen')
}
