const chainEnv = process.env.NEXT_PUBLIC_CHAIN_ENV
// if (!chainEnv) throw new Error('Missing NEXT_PUBLIC_CHAIN_ENV')

const flowAccessApiUrl = process.env.NEXT_PUBLIC_FLOW_ACCESS_API_URL
// if (!flowAccessApiUrl)
// throw new Error('Missing NEXT_PUBLIC_FLOW_ACCESS_API_URL')

const appUrl = process.env.NEXT_PUBLIC_APP_URL
// if (!appUrl) throw new Error('Missing NEXT_PUBLIC_APP_URL')

const walletDiscovery = process.env.NEXT_PUBLIC_WALLET_DISCOVERY
// if (!walletDiscovery) throw new Error('Missing NEXT_PUBLIC_WALLET_DISCOVERY')

const avatarUrl = process.env.NEXT_PUBLIC_AVATAR_URL
// if (!avatarUrl) throw new Error('Missing NEXT_PUBLIC_AVATAR_URL')

// Default Contracts

const contractFungibleToken =
  process.env.NEXT_PUBLIC_CONTRACT_FUNGIBLE_TOKEN_ADDRESS
// if (!contractFungibleToken)
// throw new Error('Missing NEXT_PUBLIC_CONTRACT_FUNGIBLE_TOKEN_ADDRESS')

const contractNonFungibleToken =
  process.env.NEXT_PUBLIC_CONTRACT_NON_FUNGIBLE_TOKEN_ADDRESS
// if (!contractNonFungibleToken)
// throw new Error('Missing NEXT_PUBLIC_CONTRACT_NON_FUNGIBLE_TOKEN_ADDRESS')

const contractMetadataViews =
  process.env.NEXT_PUBLIC_CONTRACT_METADATA_VIEWS_ADDRESS
// if (!contractMetadataViews)
// throw new Error('Missing NEXT_PUBLIC_CONTRACT_METADATA_VIEWS_ADDRESS')

const flowAddress = process.env.NEXT_PUBLIC_FLOW_ADDRESS
// if (!flowAddress) throw new Error('Missing NEXT_PUBLIC_FLOW_ADDRESS')

const contractNFTStorefront =
  process.env.NEXT_PUBLIC_CONTRACT_NFT_STOREFRONT_ADDRESS
// if (!contractNFTStorefront)
// throw new Error('Missing NEXT_PUBLIC_CONTRACT_NFT_STOREFRONT_ADDRESS')

const contractFlowToken = process.env.NEXT_PUBLIC_CONTRACT_FLOW_TOKEN_ADDRESS
// if (!contractFlowToken)
// throw new Error('Missing NEXT_PUBLIC_CONTRACT_FLOW_TOKEN_ADDRESS')

const contractFUSD = process.env.NEXT_PUBLIC_CONTRACT_FUSD_ADDRESS
// if (!contractFUSD) throw new Error('Missing NEXT_PUBLIC_CONTRACT_FUSD_ADDRESS')

// Custom Contracts

const contractCreatorBay = process.env.NEXT_PUBLIC_CONTRACT_CREATORBAY_ADDRESS
// if (!contractCreatorBay)
// throw new Error('Missing NEXT_PUBLIC_CONTRACT_CREATORBAY_ADDRESS')

const contractVoucher = process.env.NEXT_PUBLIC_CONTRACT_VOUCHER_ADDRESS
// if (!contractVoucher)
// throw new Error('Missing NEXT_PUBLIC_CONTRACT_VOUCHER_ADDRESS')

const contractNFTItemSetStorefront =
  process.env.NEXT_PUBLIC_CONTRACT_NFT_ITEMSET_STOREFRONT_ADDRESS
// if (!contractNFTItemSetStorefront)
// throw new Error('Missing NEXT_PUBLIC_CONTRACT_NFT_ITEMSET_STOREFRONT_ADDRESS')

const publicConfig = {
  faucetAddress: process.env.NEXT_PUBLIC_FAUCET_ADDRESS,
  chainEnv,
  flowAccessApiUrl,
  appUrl,
  walletDiscovery,
  flowAddress,
  avatarUrl,
  contractFungibleToken,
  contractNonFungibleToken,
  contractMetadataViews,
  contractNFTStorefront,
  contractFlowToken,
  contractFUSD,
  contractCreatorBay,
  contractVoucher,
  contractNFTItemSetStorefront,
}

export default publicConfig
