import LinkContainer from '@Components/generics/LinkContainer'
import Logotype from '@Components/navigation/Logotype'
import { Container, Typography } from '@mui/material'
import { Box } from '@mui/system'
import Image from 'next/image'
import React from 'react'

const Footer = (props) => {
  return (
    <Box
      display="flex"
      flexShrink={0}
      sx={{
        background: 'background.default',
        height: '80px',
      }}
    >
      <Container>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{ height: '80px', width: '100%' }}
        >
          <Box>
            <Typography color="textSecondary" variant="body2">
              Copyright © {new Date().getFullYear()}{' '}
              <Logotype sx={{ fontWeight: 'bold' }} />
            </Typography>
            <Box display="flex" sx={{ mt: 1 }}>
              <LinkContainer href="/terms">
                <Typography variant="body2" sx={{ mr: 1 }}>
                  Terms
                </Typography>
              </LinkContainer>
              <LinkContainer href="/privacy">
                <Typography variant="body2">Privacy</Typography>
              </LinkContainer>
            </Box>
          </Box>
          <Box>
            <LinkContainer href="https://flow.com">
              <Image
                src="/images/flow.svg"
                width={100}
                height={50}
                alt="Built on FLOW"
              />
            </LinkContainer>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default Footer
