import * as fcl from '@onflow/fcl'

export const useFlowLogout = () => {
  const logout = async () => {
    await fcl.unauthenticate()
  }

  return logout
}

export default useFlowLogout
