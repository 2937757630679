import { createRequest } from '@Firebase/request'
import { addRootMessage } from '@State/rootActions'
import store from '@State/store'

export const forceRefresh = async () => {
  let result = null
  try {
    console.log('%c scheduled-forceRefresh:', 'color:aqua')
    const req = createRequest('scheduled-forceRefresh')
    const response = await req()
    result = response.data
    console.log('%c scheduled-forceRefresh:', 'color:lightgreen', result)
  } catch (error) {
    console.log('%c scheduled-forceRefresh:', 'color:lightred', error)
    store.dispatch(addRootMessage({ type: 'error', message: error.message }))
  }
  return result
}
