import INITIALIZE_ACCOUNT_TRANSACTION from '@Cadence/transactions/initialize_account.cdc'
import { tx } from '@Flow/util/tx'

export const initializeAccount = async (opts = {}) => {
  await tx(
    {
      cadence: INITIALIZE_ACCOUNT_TRANSACTION,
      limit: 70,
    },
    opts
  )
}
