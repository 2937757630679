import { isAccountInitialized as isAccountInitializedTx } from '@Flow/script.is-account-initialized'
import { isAdminInitialized as isAdminInitializedTx } from '@Flow/script.is-admin-initialized'
import { createAction, createAsyncThunk } from '@reduxjs/toolkit'

export const setFlowUser = createAction('flow/user/setFlowUser')
export const setShowFlowUserInitialize = createAction(
  'flow/user/setShowFlowUserInitialize'
)

export const checkIsAccountInitialized = createAsyncThunk(
  'flow/user/checkIsAccountInitialized',
  async (addr) => {
    console.log('%c flow-checkIsAccountInitialized:', 'color:aqua', addr)
    let result
    try {
      result = await isAccountInitializedTx(addr)
      console.log(
        '%c flow-checkIsAccountInitialized:',
        'color:lightgreen',
        result
      )
    } catch (error) {
      console.log('%c flow-checkIsAccountInitialized:', 'color:lightred', error)
    }
    return { addr, result: result?.CreatorBay && result?.FUSD }
  }
)

export const checkIsAdminInitialized = createAsyncThunk(
  'flow/user/checkIsAdminInitialized',
  async (addr) => {
    console.log('%c flow-checkIsAdminInitialized:', 'color:aqua', addr)
    let result
    try {
      result = await isAdminInitializedTx(addr)
      console.log(
        '%c flow-checkIsAdminInitialized:',
        'color:lightgreen',
        result
      )
    } catch (error) {
      console.log('%c flow-checkIsAdminInitialized:', 'color:lightred', error)
    }
    return result?.Voucher
  }
)
