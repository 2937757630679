import { createReducer } from '@reduxjs/toolkit'
import {
  updateAccountProfile,
  uploadAvatarImage,
  uploadBannerImage,
} from '@State/firebase/firebaseProfileActions'
import {
  getFirebaseAccount,
  setFirebaseUser,
} from '@State/firebase/firebaseUserActions'

const initialState = {
  isFirebaseLoggedIn: false,
  isCheckingFirebaseAuthState: true,
  firebaseUser: null,
  isGettingFirebaseAccount: false,
  firebaseAccount: null,
  firebaseClaims: null,
  firebaseUserBanned: false,
}

export const firebaseUserReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setFirebaseUser, (state, { payload }) => {
      console.log(
        '%c firebaseUserReducer-setFirebaseUser',
        'color:lightgreen',
        payload
      )
      state.firebaseUser = payload
      state.isCheckingFirebaseAuthState = false
      state.isFirebaseLoggedIn = !!payload
      state.firebaseUserBanned = false
      if (!payload) {
        state.firebaseAccount = null
        state.firebaseClaims = null
      }
    })

    .addCase(getFirebaseAccount.pending, (state) => {
      console.log('%c firebaseUserReducer-getFirebaseAccount', 'color:yellow')
      state.isGettingFirebaseAccount = true
    })
    .addCase(getFirebaseAccount.fulfilled, (state, { payload }) => {
      console.log(
        '%c firebaseUserReducer-getFirebaseAccount',
        'color:lightgreen',
        payload
      )
      state.firebaseAccount = payload.account
      state.firebaseClaims = payload.claims
    })
    .addCase(getFirebaseAccount.rejected, (state, { payload }) => {
      console.log(
        '%c firebaseUserReducer-getFirebaseAccount',
        'color:lightred',
        payload
      )
      if (payload === 'User is banned.') {
        state.firebaseUserBanned = true
      }
    })

    .addCase(updateAccountProfile.fulfilled, (state, { payload }) => {
      console.log(
        '%c firebaseUserReducer-updateAccountProfile',
        'color:lightgreen',
        payload
      )
      state.firebaseAccount = payload
    })
    .addCase(uploadAvatarImage.fulfilled, (state, { payload }) => {
      console.log(
        '%c firebaseUserReducer-uploadAvatarImage',
        'color:lightgreen',
        payload
      )
      state.firebaseAccount = { ...state.firebaseAccount, avatarImage: payload }
    })
    .addCase(uploadBannerImage.fulfilled, (state, { payload }) => {
      console.log(
        '%c firebaseUserReducer-uploadBannerImage',
        'color:lightgreen',
        payload
      )
      state.firebaseAccount = { ...state.firebaseAccount, bannerImage: payload }
    })
})

export const selectFirebaseUser = (state) => state.firebaseUser.firebaseUser
export const selectFirebaseAccount = (state) =>
  state.firebaseUser.firebaseAccount
export const selectFirebaseClaims = (state) => state.firebaseUser.firebaseClaims
export const selectIsFirebaseLoggedIn = (state) =>
  state.firebaseUser.isFirebaseLoggedIn
export const selectIsCheckingFirebaseAuthState = (state) =>
  state.firebaseUser.isCheckingFirebaseAuthState
export const selectIsGettingFirebaseAccount = (state) =>
  state.firebaseUser.isGettingFirebaseAccount

export default firebaseUserReducer
