import useFirebaseState from '@Hooks/firebase/useFirebaseState'
import useFlowState from '@Hooks/flow/useFlowState'
import React from 'react'

const AppStateProvider = (props) => {
  useFirebaseState()
  useFlowState()

  return <>{props.children}</>
}

export default AppStateProvider
