import { initializeAccount as initializeAccountTx } from '@Flow/tx.initialize-account'
import { checkIsAccountInitialized } from '@State/flow/flowUserActions'
import { selectFlowUser } from '@State/flow/flowUserReducer'
import {
  DECLINE_RESPONSE,
  ERROR,
  IDLE,
  setTransactionState,
  START,
  SUCCESS,
} from '@State/flow/transactionActions'
import { useAppDispatch, useAppSelector } from '@State/hooks'
import { addRootMessage } from '@State/rootActions'
import { useState } from 'react'

const useAccountInitializer = () => {
  const flowUser = useAppSelector(selectFlowUser)
  const [isInitializing, setIsInitializing] = useState(false)

  const dispatch = useAppDispatch()

  const initializeAccount = () => {
    if (!flowUser?.addr) return

    console.log(
      '%c useAccountInitializer initializeAccount',
      'color:cyan',
      flowUser.addr
    )

    initializeAccountTx({
      onStart() {
        dispatch(setTransactionState(START))
        setIsInitializing(true)
      },
      onSuccess: () => {
        dispatch(checkIsAccountInitialized(flowUser.addr))
        dispatch(setTransactionState(SUCCESS))
        setIsInitializing(false)
      },
      onError(e) {
        if (e === DECLINE_RESPONSE) {
          dispatch(setTransactionState(IDLE))
        } else {
          console.log(
            '%c useAccountInitializer initializeAccount',
            'color:lightred',
            e
          )
          dispatch(setTransactionState(ERROR))
          dispatch(
            addRootMessage({
              type: 'error',
              message: 'Failed to initialize account',
            })
          )
        }
        setIsInitializing(false)
      },
    })
  }

  return { initializeAccount, isInitializing }
}

export default useAccountInitializer
