import { createReducer } from '@reduxjs/toolkit'
import { addRootMessage, dismissRootMessage } from '@State/rootActions'

const initialState = {
  messages: [],
}

export const rootReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(addRootMessage, (state, { payload }) => {
      state.messages = [...state.messages, payload]
    })

    .addCase(dismissRootMessage, (state) => {
      state.messages = state.messages.slice(1)
    })
})

export const selectRootMessages = (state) => state.root.messages

export default rootReducer
