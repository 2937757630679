import GenericMenu from '@Components/generics/GenericMenu'
import LinkContainer from '@Components/generics/LinkContainer'
import Logotype from '@Components/navigation/Logotype'
import { forceRefresh } from '@Fetch/firebase/admin'
import useFirebaseLogout from '@Hooks/firebase/useFirebaseLogout'
import useFlowLogin from '@Hooks/flow/useFlowLogin'
import useFlowLogout from '@Hooks/flow/useFlowLogout'
import AdminIcon from '@mui/icons-material/LocalPolice'
import MoreIcon from '@mui/icons-material/MoreVert'
import RefreshIcon from '@mui/icons-material/Refresh'
import {
  AppBar,
  Button,
  Container,
  IconButton,
  Toolbar,
  Typography,
} from '@mui/material'
import { blue } from '@mui/material/colors'
import { Box } from '@mui/system'
import {
  selectFirebaseClaims,
  selectFirebaseUser,
} from '@State/firebase/firebaseUserReducer'
import { selectFlowUser } from '@State/flow/flowUserReducer'
import { useAppSelector } from '@State/hooks'
import { useRouter } from 'next/router'
import React, { forwardRef, useState } from 'react'

const NavBar = forwardRef((props, ref) => {
  const [profileAnchor, setProfileAnchor] = useState(null)
  const [mobileAnchor, setMobileAnchor] = useState(null)

  const firebaseClaims = useAppSelector(selectFirebaseClaims)
  const firebaseUser = useAppSelector(selectFirebaseUser)
  const flowUser = useAppSelector(selectFlowUser)

  const firebaseLogout = useFirebaseLogout()
  const flowLogout = useFlowLogout()
  const flowLogin = useFlowLogin()

  const router = useRouter()

  const hideAnchors = () => {
    setProfileAnchor(null)
    setMobileAnchor(null)
  }

  const doRefreshEvents = async () => {
    await forceRefresh()
    console.log('forceRefresh: complete')
  }

  const profileItems = {}

  profileItems.Profile = {
    title: 'Profile',
    clickHandler: () => {
      hideAnchors()
      router.push('/profile/edit')
    },
  }
  profileItems.Support = {
    title: 'Support',
    clickHandler: () => {
      hideAnchors()
      router.push('/support')
    },
  }
  if (firebaseUser) {
    profileItems['Sign out'] = {
      title: 'Sign out',
      clickHandler: () => {
        hideAnchors()
        firebaseLogout()
        flowLogout()
      },
    }
  }
  if (flowUser?.addr) {
    profileItems['Sign out (Wallet)'] = {
      title: 'Sign out (Wallet)',
      clickHandler: () => {
        hideAnchors()
        flowLogout()
      },
    }
  } else {
    profileItems['Sign in (Wallet)'] = {
      title: 'Sign in (Wallet)',
      clickHandler: () => {
        hideAnchors()
        flowLogin()
      },
    }
  }

  const mobileItems = {
    Home: {
      title: 'Home',
      href: '/',
    },
    Marketplace: {
      title: 'Marketplace',
      href: '/marketplace',
    },
  }

  if (firebaseClaims?.creator) {
    mobileItems['Creator Dashboard'] = {
      title: 'Creator Dashboard',
      href: '/creator',
    }
  } else {
    mobileItems.Creators = {
      title: 'Creators',
      href: '/creators',
    }
  }

  if (flowUser?.addr) {
    mobileItems['My Collection'] = {
      title: 'My Collection',
      href: `/collection/${flowUser.addr}`,
    }
  }

  mobileItems['My Account'] = {
    title: 'My Account',
    anchorSetter: setProfileAnchor,
  }

  return (
    <AppBar
      ref={ref}
      elevation={0}
      sx={{ backgroundColor: 'background.default', color: '#fff' }}
    >
      <Container>
        <Toolbar
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            p: '0 !important',
          }}
        >
          <Box display="flex" flexWrap="wrap" alignItems="center">
            <LinkContainer href="/">
              <Typography variant="h5" noWrap sx={{ textDecoration: 'none' }}>
                <Logotype sx={{ fontWeight: 'bold' }} />
              </Typography>
            </LinkContainer>
            <Box
              sx={{
                py: 0.25,
                px: 0.5,
                backgroundColor: blue[500],
                ml: 1,
                borderRadius: '4px',
              }}
            >
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                BETA
              </Typography>
            </Box>
          </Box>

          <Box display="flex" alignItems="center">
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              <LinkContainer href="/">
                <Button color="white" aria-label="home" sx={{ mr: 1 }}>
                  Home
                </Button>
              </LinkContainer>

              <LinkContainer href="/marketplace">
                <Button color="white" aria-label="marketplace" sx={{ mr: 1 }}>
                  Marketplace
                </Button>
              </LinkContainer>

              {flowUser?.addr && (
                <LinkContainer href={`/collection/${flowUser.addr}`}>
                  <Button color="white" aria-label="collection">
                    My Collection
                  </Button>
                </LinkContainer>
              )}

              <Box
                sx={{
                  height: '36px',
                  width: '1px',
                  backgroundColor: 'rgba(255,255,255,0.5)',
                  mx: 1,
                }}
              />

              {firebaseUser && (
                <Button
                  color="inherit"
                  aria-label="my-account"
                  sx={{ mr: 1 }}
                  onClick={(e) => setProfileAnchor(e.currentTarget)}
                >
                  My Account
                </Button>
              )}

              {!firebaseUser && (
                <LinkContainer href={`/sign-in?r=${router.pathname}`}>
                  <Button
                    color="primary"
                    aria-label="sign-in"
                    variant="contained"
                    sx={{ mr: 1 }}
                  >
                    Sign in
                  </Button>
                </LinkContainer>
              )}

              {!firebaseClaims?.creator && (
                <LinkContainer href="/creators">
                  <Button
                    color="secondary"
                    aria-label="creators"
                    variant="contained"
                    sx={{ mr: 1 }}
                  >
                    Creators
                  </Button>
                </LinkContainer>
              )}

              {firebaseClaims?.creator && (
                <LinkContainer href="/creator">
                  <Button
                    color="secondary"
                    aria-label="creator-dashboard"
                    variant="contained"
                    sx={{ mr: 1 }}
                  >
                    Creator Dashboard
                  </Button>
                </LinkContainer>
              )}

              {firebaseClaims?.admin && (
                <LinkContainer href="/admin">
                  <IconButton
                    size="small"
                    color="success"
                    aria-label="admin"
                    sx={{ ml: 1 }}
                  >
                    <AdminIcon />
                  </IconButton>
                </LinkContainer>
              )}

              {firebaseClaims?.admin && (
                <IconButton
                  size="small"
                  color="error"
                  aria-label="refresh"
                  sx={{ ml: 1 }}
                  onClick={doRefreshEvents}
                >
                  <RefreshIcon />
                </IconButton>
              )}
            </Box>

            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                color="inherit"
                aria-label="more"
                onClick={(e) => setMobileAnchor(e.currentTarget)}
              >
                <MoreIcon />
              </IconButton>
            </Box>
          </Box>

          <GenericMenu
            anchor={mobileAnchor}
            items={Object.keys(mobileItems).map((k) => mobileItems[k])}
            onClick={(e, item) => {
              mobileItems[item.title].onClick
                ? mobileItems[item.title].onClick()
                : mobileItems[item.title].anchorSetter(e.currentTarget)
              setMobileAnchor(null)
            }}
            onClose={() => setMobileAnchor(null)}
          />
          <GenericMenu
            anchor={profileAnchor}
            items={Object.keys(profileItems).map((k) => profileItems[k])}
            onClick={(e, item) => profileItems[item.title].clickHandler()}
            onClose={() => setProfileAnchor(null)}
          />
        </Toolbar>
      </Container>
    </AppBar>
  )
})

NavBar.displayName = 'NavBar'

export default NavBar
